import React from "react";
import { Link } from "./../Link";
import styles from "./Footer.module.scss";
import { FacebookIcon, TwitterIcon, InstagramIcon, LineIcon } from "./../icons";

const SIZE = 32;

const Footer = () => (
    <div className={`container ${styles.container}`}>
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center m-4">
            <a href="https://www.instagram.com/kyoto_fine_wine_and_spirits/"
               className={styles.brandLinks} target="_blank">
                <InstagramIcon size={SIZE} />
            </a>
            <a href="https://www.facebook.com/kyotofinewineandspirits/"
               className={styles.brandLinks} target="_blank">
                <FacebookIcon size={SIZE} />
            </a>
            <a href="https://twitter.com/KyotoFine" className={styles.brandLinks} target="_blank">
                <TwitterIcon size={SIZE} />
            </a>
            <a href="https://line.me/R/ti/p/@042zakpe?from=page&openQrModal=true&searchId=042zakpe" className={styles.brandLinks} target="_blank">
                <LineIcon size={SIZE} />
            </a>
        </div>
        <div className="text-center">
            <a href="/specified-commercial-transaction-act-notes" className={styles.footerLinks}>
                特定商取引法に基づく表記
            </a>
        </div>
    </div>
);

export default Footer;
