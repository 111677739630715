import styles from "./CompanyPage.module.scss";
import React from 'react';
import {Table} from "react-bootstrap";

export const SCTANotesPage = () =>
    <div className="container">
            <Table className={styles.table}>
                    <caption className={styles.caption}>特定商取引法に基づく表記</caption>
                    <tbody>
                    <tr>
                            <th>販売業社の名称</th>
                            <td>Kyoto Fine Wine and Spirits</td>
                    </tr>
                    <tr>
                            <th>所在地</th>
                            <td>
                                    6068344
                                    <br/>
                                    京都市左京区岡崎円勝寺町58
                            </td>
                    </tr>
                    <tr>
                            <th>電話番号</th>
                            <td>
                                    09011553104
                                    <br/>
                                    <br/>
                                    受付時間 10:00-18:00（土日祝を除く）<br/>
                                    メールアドレス kyotofinewineandspirits@gmail.com
                            </td>
                    </tr>
                    <tr>
                            <th>運営統括責任者</th>
                            <td>王子武貞</td>
                    </tr>
                    <tr>
                            <th>追加手数料等の追加料金</th>
                           <td>
                                   <ul>
                                           <li>配送料（一律1100円）</li>
                                           <li>手数料（代引き費用440円)</li>
                                   </ul>
                           </td>
                    </tr>
                    <tr>
                            <th>交換および返品（返金ポリシー）</th>
                            <td>
                                    飲食品の為、お客様都合の返品・交換は承っておりません。<br/>
                                    商品に不備がある場合、当社の送料負担にて返金又は新しい商品と交換いたします。まずはkyotofinewineandspirits@gmail.comまでご連絡ください。
                            </td>
                    </tr>
                    <tr>
                            <th>引渡時期</th>
                            <td>注文は 3 ～ 5 営業日以内に処理され、商品は 14 日以内に到着します。</td>
                    </tr>
                    <tr>
                            <th>受け付け可能な決済手段</th>
                            <td>クレジットカードまたは国内の銀行振込、代金引換</td>
                    </tr>
                    <tr>
                            <th>決済期間</th>
                            <td>クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から 3 日以内にお振り込みいただく必要があります。</td>
                    </tr>
                    </tbody>
            </Table>
    </div>;